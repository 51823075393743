<script>
import AboutUs from '@/components/comomt/AboutUsContent.vue'
import AboutUsTeam from '@/components/comomt/AboutUsTeam.vue'
import Footer from '@/components/footer.vue'
import AboutUSPartner from '@/components/comomt/AboutUSPartner.vue'
import AboutUsheader from '@/components/comomt/AboutUsheader.vue'


export default {
    components:{
        AboutUs,
        AboutUsTeam,
        Footer,
        AboutUSPartner,
        AboutUsheader,


    }
}
</script>
<template>
    <div>
        <AboutUsheader></AboutUsheader>
        <AboutUs></AboutUs>
        <AboutUsTeam></AboutUsTeam>
        <AboutUSPartner></AboutUSPartner>
        <Footer></Footer>
    </div>

</template>
<style>
</style>