import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import BusinessIntro from '@/views/BusinessIntro.vue'
import AboutUs  from '@/views/AboutUs.vue';
Vue.use(Router);  // 安装 Vue Router
const routes=[
  {
    path: '/',  // 首页路由
    name: 'Home',
    component: Home,
    meta: { title: 'LE.CN' }
  },
  {
    path: '/BusinessIntro',  
    name: 'BusinessIntro',
    component: BusinessIntro,
  },
  {
    path:'/AboutUs',
    name:'AboutUs',
    component:AboutUs
  }
]
const router = new Router({ routes });
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router