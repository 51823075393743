<template>
    <el-tooltip :placement="placement" effect="dark">
      <template #content>
        <div v-html="content"></div> <!-- 使用 v-html 渲染多行内容 -->
      </template>
      <img :src="imgsrc" >
    </el-tooltip>
  </template>
  
  <script>
  export default {
    props: {
      imgsrc: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      placement: {
        type: String,
        default: "top"
      }
    }
  };
  </script>