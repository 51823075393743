
<script>
import Biaodan from './comomt/biaodan.vue';
import axiosInstance  from '@/request';
export default {
    components:{
        Biaodan,
    },
    data(){
        return{
            datalist:[],//类目列表

            
        }
    },
    methods:{
        getdomainlist(){
            axiosInstance.get('/api/overseas/seeks')
            .then((res) =>{
                console.log(res)
                this.datalist = res || []
            })
            .catch((error) => {
                console.error("请求失败：", error);
            });
        }

    },
    mounted(){
        this.getdomainlist()
    }
}
</script>
<template>
<div class="content">
    <Biaodan v-model="datalist"></Biaodan>
</div>

</template>
<style>
.content{
    background-color: #F3F6FC;
    padding: 32px 0px;
}
.ymlist ul li{
    cursor: pointer;
}
@media (max-width:768px) {
    .content img{
        width: 18px;
        height: 18px;
        margin: 0;
    }
    .secondFormbd,.ThirdFormbd{
        margin-top: 15px;
    }
    .contentbd,.secondFormbd,.ThirdFormbd{
        border-radius: 8px;
    }
    .content h1{
        font-size: 16px;
    }
    .contentbdbt,.secondFormbdbt,.ThirdFormbdbt{
        padding: 20px 15px 15px 15px;
    }
    .content ul li{
        width: calc(100% / 2);
        border-right: none; /* 默认移除右侧边框 */
        border-top: none; /* 默认移除顶部边框 */
    }
    /* 每行的第一个元素显示右侧边框 */
    .content ul li:nth-child(n) {
    border-right: 1px solid #EBEEF5;
    }
    .content ul li:nth-child(n+3){
        border-top: 1px solid #EBEEF5;

    }
    .ymlist ul li{
        padding: 17px 18px 17px 16px;
        font-size: 15px;
    }
    
}
</style>