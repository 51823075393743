<template>
    <div class="header">
      <Navigate></Navigate>
      <div class="main-content">
        <div class="contenttext">
            <p><span>LE.CN <br></span>DOMAIN INVESTMENT</p>
            <div class="contenttextp">
                LE.CN (深圳市乐域科技服务有限公司) is an joint company founded by our shareholders in 2014.<br>
                We are major Premium Domain Investor and Broker in China.<br>
                We invest in premium domain names on behalf of our shareholders and domain name investors.<br>
                We serve premium domain owners with broker service and domain name liquidity service.<br>
                We provide mortgage loan service for premium domain name owners.<br>
            </div>
            <div class="contenttextpYd">
              LE .CN (深圳市微联科技服务有限公司） is an joint company founded by our shareholders in 2014. We are major Premium Domain Investor and Broker in China. 
            </div>
        </div>
        <div class="more-info-btn">
            <router-link to="/AboutUs">
                <div class="morebt"><span>more abou tus</span>
                    <img src="@/assets/images/jtright.png" class="morejt">
                </div>
           
            </router-link>
        </div>
 

    </div>
    </div>
  </template>
  
  <script>
  import Navigate from '@/components/comomt/Navigate.vue'
  export default {
    methods: {
      leaveMessage() {
        alert("Message functionality can be added here.");
      }
    },
    components:{
      Navigate
    }
  }
  </script>
  
  <style scoped>
  .header {
    width: 100%;
    background-image: url('../assets/images/headerbg.png');
    background-position: center center;  /* 背景图片居中 */
    background-repeat: no-repeat;  /* 防止背景图片重复 */
    height: 480px;
    
}
  .contenttext p{
    margin-right: 5px;
    color: #131522;
    margin: 0px;
    margin-top: 27;
  }
  .contenttext p span{
    color: #FF7800;
    font-size: 56px;

  }
  .contenttextp {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 17px;
    color: #131522;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    margin-top: 27px;
  }
.morebt{
    background: #FFD4BC;
    border-radius: 27px;
    border: 1px solid #FF9A5E;
    font-size: 14px;
    margin-top: 203px;
    display: flex;
    align-items: center;
  
}
.morebt:hover{
  background-color: #FFAF80;
}
.morebt span{
    margin: 9px 28px 9px 16px;
    color: #131522;
}
.more-info-btn{
   display: flex;
   align-items: flex-end;
}

.social a{
  display: flex;
}
.contenttextpYd{
  display: none;
}
@media (max-width:768px) {
  .contenttextpYd{
    display: block;
    font-weight: 400;
    font-size: 15px;
    color: #131522;
    line-height: 24px;
    margin-top: 15px;
  }
  .contenttextp{
    display: none;
  }
  .main-content{
    flex-direction: column;
    margin: 0px;
    margin-top: 32px;
  }
  .contenttext{
    width: 100%;
    height: auto;

  }
  .more-info-btn{
    margin-left:0;
  }
  .header{
    width: 100%;
    background-image: url('../assets/images/ydbg.png');
    background-position: center center;  /* 背景图片居中 */
    background-repeat: no-repeat;  /* 防止背景图片重复 */
    height: 347px;
    padding: 0px 15px;
  }
  .morebt{
    margin-top: 15px;
    width: 132px;
  }
  .morebt span{
    margin: 7px 5px 8px 11px;
  }
  .morejt{
    width: 20px;
    height: 20px;
  }
  .contenttext p{
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
  }

}
  </style>
  