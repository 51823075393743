<script>
import Navigation from '@/components/navigation.vue'
import DomainType from '@/components/comomt/DomainType.vue'
import Footer from '@/components/footer.vue'
export default {
    components:{
        Navigation,
        DomainType,
        Footer
    }
}
</script>
<template>
    <div>
        <Navigation></Navigation>
        <DomainType></DomainType>
        <Footer></Footer>

    </div>


</template>