<script>
import IconTooltip from '@/components/comomt/IconTooltip.vue'
import axiosInstance  from '@/request';
export default {
    components:{
            IconTooltip
        },
    props: {
    visible: {  // 定义接收的 prop
      type: Boolean,
      required: true
    },
    ruleForm:{
        type: Object,
        required: false
    }
  },
    data(){
        return {
            // drawer: true,
            drawerSize:'30%',
            drawerDirection: "rtl", // 默认方向：PC端从右侧弹出
            rules:{
            YourName:[
            { required: true, message: '请输入您的名字', trigger: 'blur' },
            { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
            ],
            email: [
            { required: true, message: "邮箱不能为空", trigger: "blur" },
            { type: "email", message: "邮箱格式不正确", trigger: "blur" }
            ],
            content:[
            { required: true, message: '请输入描述文案', trigger: 'blur' },
            { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
            ]
         },
         iconContent:[
            {
                imgsrc:require("@/assets/images/Email.png"),
                content:'<div>Email：Alan@le.cn<span>copy</span></div>'

            },
            {
                imgsrc:require("@/assets/images/Facebook.png"),
                content:'<div>Email：Alan@le.cn<span>copy</span></div>'

            },
            {
                imgsrc:require("@/assets/images/skype.png"),
                content:'<div>Email：Alan@le.cn<span>copy</span></div>'

            },
            {
                imgsrc:require("@/assets/images/Linkedin.png"),
                content:'<div>Email：Alan@le.cn<span>copy</span></div>'

            },
            {
                imgsrc:require("@/assets/images/Twitter.png"),
                content:'<div>Email：Alan@le.cn<span>copy</span></div>'

            }
        ],
        }
    },
    methods:{
        submitForm(){
            const ruleForms = this.drawerDirection === 'rtl' ? 'ruleForm' : 'ruleForm1';
            if(this.$refs[ruleForms]){
                this.$refs[ruleForms].validate((valid) => {
            if (valid) {
            const domainbd = {
                domain:this.ruleForm.domainName || "",
                contact:this.ruleForm.email,
                realName:this.ruleForm.YourName,
                message:this.ruleForm.content
            }
            axiosInstance.post('/api/advisory/records',domainbd)
            .then((res) =>{
                if(res.data.code == 0){
                    this.$message({
                        message:"信息提交成功",
                        type:'success'
                    });
                    this.$refs[ruleForms].resetFields();
                }
            })
            .catch((error) =>{
                console.error("POST 请求失败：", error);
                this.$message({
                        message:"信息错误",
                        type:'warning'
                });
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })

      }else{
        console.error(`表单 ${ruleForms} 未找到`);
        this.$message({ message: '表单加载失败，请刷新重试', type: 'error' });
      }

    },
        checkScreenSize() {
            if (window.innerWidth <= 768) { // 判断是否为移动端
                this.drawerDirection = "ttb"; // 移动端从顶部弹出
                this.drawerSize = "80%"; // 移动端高度为50%
            } else {
                this.drawerDirection = "rtl"; // PC端从右侧弹出
                this.drawerSize = "30%"; // PC端宽度为50%
                
            }
        },
    closebd(){
        this.drawerVisible = false
        // this.drawer=false
        console.log(1111)
    }
    },
    computed:{
        drawerVisible:{
        get(){
            return this.visible
        },
        set(val){
            this.$emit('update:visible', val);
        }

        }

    },
    mounted(){
    this.$nextTick(() => {
    this.checkScreenSize(); // 确保 DOM 渲染后再检查
    });
     window.addEventListener('resize', this.checkScreenSize);

  },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenSize); // 移除监听
    },
    
}

</script>
<template>
        <el-drawer
            title=""
            :visible.sync="drawerVisible"
            :with-header="true"
            :direction="drawerDirection"
            :size="drawerSize"
            show-close
            :withHeader="false"
            >
            <div class="MessageForm" v-if="drawerDirection === 'rtl'">
                <div class="MessageFormstart">
                    <h2>Contact us</h2>
                    <p>Please improve your information. LE.CN  service team will contact you at the first time</p>
                </div>
                <div class="FormList">
                    <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm" class="FormLists">
                    <el-form-item label="Favorite domain" class="FormListItem">
                        <el-input v-model="ruleForm.domainName"></el-input>
                    </el-form-item>
                    <el-form-item label="Your name" class="FormListItem" prop="YourName">
                        <el-input v-model="ruleForm.YourName"></el-input>
                    </el-form-item>
                    <el-form-item label="Email" class="FormListItem" prop="email">
                        <el-input v-model="ruleForm.email" class="FormListIteminput"></el-input>
                    </el-form-item>
                    <el-form-item label="Your message" class="FormListItemTare" prop="content">
                        <el-input v-model="ruleForm.content" type="textarea"></el-input>
                    </el-form-item>
                    </el-form>
                    <div class="contactus"><div class="contactusText" @click="submitForm()">Contact us</div></div>
                </div>
            </div>
            <div class="MessageFormYd" v-if="drawerDirection === 'ttb'">
                <div class="MessageFormYdHeader">
                    <img src="@/assets/images/closeicon.png" @click="closebd">
                    <span>Contact us</span>

                </div>
                <div class="MessageFormYdContent">
                    <p>Please improve your information. LE.CN  service team will contact you at the first time</p>

                </div>
                <div class="MessageFormYdFooter">
                    <el-form  label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm1" class="FormLists">
                    <el-form-item label="Favorite domain" class="FormListItem">
                        <el-input v-model="ruleForm.domainName"></el-input>
                    </el-form-item>
                    <el-form-item label="Your name" class="FormListItem" prop="YourName">
                        <el-input v-model="ruleForm.YourName"></el-input>
                    </el-form-item>
                    <el-form-item label="Email" class="FormListItem" prop="email">
                        <el-input v-model="ruleForm.email" class="FormListIteminput"></el-input>
                    </el-form-item>
                    <el-form-item label="Your message" class="FormListItemTare" prop="content">
                        <el-input v-model="ruleForm.content" type="textarea"></el-input>
                    </el-form-item>
                    </el-form>
                    <div class="contactus"><div class="contactusText" @click="submitForm()">Contact us</div></div>

                </div>
                <div class="Morecontact">
                    <p>More contact information</p>
                    <div class="MorecontactImg">
                        <IconTooltip 
                        v-for="(item,index) in iconContent"
                        :key="index"
                        :imgsrc="item.imgsrc"
                        :content="item.content"
                        ></IconTooltip>
                    </div>
                </div>
            </div>
        </el-drawer>
</template>
<style>
.contactusText{
    cursor: pointer;
}
</style>