<script>
import Popup from '@/components/comomt/Popup.vue'

export default {
  components:{
    Popup
  },
  data(){
    return {
      drawer:false,
      visible:false,
      direction:'ttb',
      navItems:[
        {
          name:"HOME",
          path:'/',
          icon: require("@/assets/images/iconxz.png") // 首页图标

        },
        {
          name:"About business",
          path:'/BusinessIntro',
          icon: require("@/assets/images/iconxz.png") // 首页图标

        }
      ],
      ruleForm:{
            domainName: '',
            YourName: '',
            email: '',
            content:''
      },
    }
  },
  methods:{
    bdliuyan(){
      this.visible = true;
    },
    goToHome(){
      this.$router.push({ path: '/' });
    }

  },
  computed: {
    currentPage() {
      return this.$route.path; // 获取当前页面路径
    }
  }
}

</script>
<template>
    <header class="headerContent">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="LE.CN Logo" @click="goToHome"/>
        </div>
        <div class="menu">
          <ul>
            <li v-for="item in navItems" :key="item.path">
              <router-link :to="item.path">{{item.name}}</router-link>
              <img v-if="item.icon && item.path === currentPage" :src="item.icon" alt="active-icon" />
            </li>
          </ul>
          <div class="cta" @click="bdliuyan">leave a message</div>
        </div>
        <!-- <div class="social">
          <a href="https://facebook.com" target="_blank"><img src="@/assets/images/Email.png"/></a>
          <a href="https://twitter.com" target="_blank"><img src="@/assets/images/Facebook.png"/></a>
          <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/skype.png"/></a>
          <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/Linkedin.png"/></a>
          <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/Twitter.png"/></a>
        </div> -->

                <!-- 弹出组件 -->
        <Popup 
            :visible="visible" 
            :ruleForm="ruleForm"
            @update:visible="visible = $event"
        >
        </Popup>
        <div class="headerYd">
        <div class="mdlogo">
          <img src="@/assets/images/logo.png" alt="LE.CN Logo"   @click="goToHome"/>
        </div>
        
        <div class="headerYdLeft">
          <img src="@/assets/images/NavigateIcon.png" class="NavigateIcon" @click="drawer = true" type="primary">

        </div>
        <el-drawer
          :visible.sync="drawer"
          :direction="direction"
          show-close
          :withHeader="false"
          size='296'
          >
          <div class="headertan">
            <div class="headertantop">
              <img src="@/assets/images/logo.png" alt="LE.CN Logo"  class="headertantoplogo"/>
              <img src="@/assets/images/closeicon.png" class="closeicon" @click="drawer=false">

            </div>
            <div class="guodu"></div>
            <div class="headertanbt">
              <router-link to="/">HOME</router-link>
              <router-link to="/AboutUs">ABOUT US</router-link>
              <router-link to="/BusinessIntro">ABOUT BUSINESS</router-link>
            </div>
            

          </div>
        </el-drawer>
        
      </div>

      </header>

</template>
<style>

.headerContent {
  display: flex;
  padding-top: 16px;
  margin: 0px 200px;
  align-items: center;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo img {
  width: 127px;
  height: 48px;
}
.menu {
  font-size: 18px;
  line-height: 27px;
  color: #131522;
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
}
.menu ul {
  display: flex;
}
.menu ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 32px;
  height: 40px;
  line-height: 40px;
}
.menu ul li img {
  width: 16px;
  height: 10px;
  margin-bottom: 4px;
}
.menu ul li a {
  color: #131522;
  text-decoration: none;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}
/* .menu ul li a:hover {
  color: #FFAF80;
} */
.social {
  margin-left: 457px;
  display: flex;
  align-items: center;
}
.social img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.buttonmav {
  width: 184px;
  height: 50px;
  background: #FFAF80;
  border-radius: 25px;
  color: #131522;
}
.menu {
  margin-left: 57px;
}
.main-content {
  margin-left: 250px;
  margin-top: 79px;
  height: auto;
  display: flex;
}
.menu a {
  color: #131522;
}
.cta {
  width: 184px;
  height: 50px;
  background: #FFAF80;
  border-radius: 25px;
  font-size: 16px;
  color: #131522;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cta:hover {
  background-color: #FF9A5E;
  cursor: pointer;
}
.headerYd {
  display: none;
}
.social a {
  display: flex;
}
@media (max-width: 768px) {
  .menu, .social, .cta, .logo {
    display: none;
  }
  .headerYd {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ej, .NavigateIcon {
    width: 25px;
    height: 25px;
  }
  .ej {
    margin-right: 20px;
  }
  .NavigateIcon {
    padding: 4px 3px 3px 4px;
  }
  .mdlogo img, .headertantoplogo {
    width: 75px;
    height: 28px;
  }
  .sanheng {
    display: flex;
    flex-direction: column;
  }
  .sanheng span {
    width: 25px;
    height: 25px;
    background: #000000;
  }
  .headerYdLeft {
    display: flex;
  }
  .headerContent {
    margin: 0;
  }
  .headertantop {
    display: flex;
    justify-content: space-between;
    padding: 10px 9px 15px 15px;
    align-items: center;
  }
  .closeicon {
    width: 17px;
    height: 17px;
  }
  .headertanbt {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 44px 15px;
  }
  .headertanbt a {
    border-bottom: 1px solid #F3F6FC;
    padding: 15px 0px;
    font-size: 16px;
    color: #1A1E34;
  }

  .guodu {
    width: 100%;
    height: 10px;
    background-color: #F3F6FC;
  }
}
</style>