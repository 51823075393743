<script>
import Popup from '@/components/comomt/Popup.vue'
export default {
    components:{
        Popup
    },
    data(){
        return{
            visible:false,
            ruleForm:{
            domainName: '',
            YourName: '',
            email: '',
            content:''},
        
        }
    },
    methods:{
    bdliuyan(){
      this.visible = true;
    }
    }
}
</script>
<template>
    <div class="footer">
        <div class="footerContent">
            <div class="footerContentlx">
                <h2>How to contact us</h2>
                <p>Please contact us via contact information below for sale or purchase Premium Domains</p>

            </div>
            <div class="footerContentpt">
                <!-- <div class="footerContentptlb">
                    <a href="https://facebook.com" target="_blank"><img src="@/assets/images/FooterEmail.png"/></a>
                    <a href="https://twitter.com" target="_blank"><img src="@/assets/images/Footerbook.png"/></a>
                    <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/Footerskype.png"/></a>
                    <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/FooterLinkedin.png"/></a>
                    <a href="https://linkedin.com" target="_blank"><img src="@/assets/images/FooterTwitter.png"/></a>

                </div> -->
                <div class="footerContentptbt" @click="bdliuyan">leave a message</div>

            </div>
            <Popup 
                :visible="visible" 
                :ruleForm="ruleForm"
                 @update:visible="visible = $event"
            />
            <div class="footerContentdz">
                <div class="footerContentdzlb">
                    <div class="SzAdress">
                            <div class="SzAdressls"><img src="@/assets/images/footeradress.png"/><span>Shenzhen</span></div>
                            <p>18D, jiajiahao business building, No. 10168, Shennan Avenue, Nanshan District, Shenzhen, China</p>
                    </div>
                    <div class="fgx"></div>
                    <div class="HkAdress">
                            <div class="HkAdressls"><img src="@/assets/images/footeradress.png"/><span>Hongkong</span></div>
                            <p>Unit B, 22 / F, Arthur comm building, No.33 Arthur street, Yau Ma Tei, KL, Hongkong, China</p>

                    </div>

                </div>

            </div>

        </div>
        
        <div class="Copyright">© 2016-2025 LE. Cn copyright Guangdong ICP Bei No. 16001344</div>
    </div>
 

</template>
<style lang="css">
.footer{
    width: auto;
    height: 790px;
    background: #131522;
    display: flex;
    flex-direction: column;

}
.footerContent{
    width: 1065px;
    margin:  0 auto;
    padding-top: 137px;
    flex: 1;
}
.footerContentlx{
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.footerContentlx h2{
    font-size: 40px;
    font-size: 500;
    line-height: 60px;
    padding-bottom: 5px;

}
.footerContentlx p{
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
}
.footerContentpt{
    margin-top: 90px;
    display: flex;
    justify-content: center;
}
.footerContentptlb{
    width: 298px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #FFAF80;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  
}
.footerContentptlb a{
    margin-right: 24px;
    display: flex;
}
.footerContentptlb img{
    width: 24px;
    height: 24px;
}
.footerContentptlb a:last-child{
    margin-right: 0px;
}
.footerContentptbt{
    width: 184px;
    height: 50px;
    background: #FFAF80;
    border-radius: 25px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #131522;
}
.SzAdress p,.HkAdress p{
    color: #8691A8;
    font-size: 17px;
    line-height: 30px;
}
.SzAdressls{
    display: flex;
    margin-right: 32px;

}
.HkAdressls{
    display: flex;
}
.SzAdressls img,.HkAdress img{
    width: 20px;
    height: 20px;
}
.SzAdressls span,.HkAdress span{
    font-size: 17px;
    color:#FF7800;
    line-height: 26px;
    margin-left: 8px;
}
.fgx{
    width: 1px;
    height: 117px;
    background: #595A60;
    opacity: 0.28;
}
.footerContentdzlb {
    display: flex;
}
.HkAdress {
    margin-left: 32px;
}
.SzAdress {
    margin-right: 32px;
}
.Copyright{
    height: 60px;
    background-color: #1E202F;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8691A8;

}
.footerContentdz {
    padding-top: 55px;
}

.footerContentptbt:hover{
    background-color: #FF9A5E;
    cursor: pointer;
}

@media (max-width: 768px) {
    .footer{
        height: auto;
    }
    .footerContent{
        width: 100%;
        padding-top:49px;
        padding-left: 15px;

    }
    .footerContentlx{
        align-items: flex-start;
    }
    .footerContentlx h2 {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 30px;
    }
    .footerContentlx p{
        font-weight: 400;
        font-size: 15px;
        color: #C5CFE3;
        line-height: 24px;
    }
    .footerContentpt{
        margin-top: 15px;
        align-items: center;
        justify-content: flex-start;
    }
    .footerContentptlb{
       border: none;
       margin-right: 13px;
       width: auto;



    }
    .footerContentdzlb{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .footerContentptlb a{
        margin-right: 10px;
    }
    .footerContentdz{
        padding-top: 51px;
    }
    .fgx{
        display: none;
    }
    .SzAdress,.HkAdress{
        margin: 0;
    }
    .SzAdress{
        padding-bottom: 20px;
    }
    .Copyright{
        font-weight: 400;
        font-size: 10px;
        color: #8991B6;
        line-height: 15px;
    }
    .footerContentptbt{
        width: 132px;
        height: 30px;
        font-size: 14px;
        color: #1A1E34;
        line-height: 21px;
    }
    
}
</style>