<script>
import Navigation from '@/components/navigation.vue'
import Content from '@/components/content.vue'
import Footer from '@/components/footer.vue'
export default {
    components:{
        Navigation,
        Content,
        Footer

    }
}
</script>
<template>
    <div id="app">
        <Navigation></Navigation>
        <Content></Content>
        <Footer></Footer>

    </div>


</template>
<style lang="css" scoped>

</style>