
<template>
    <div>
        <div class="contentbd" v-for="(item, index) in value" :key="item.Id">
            <div class="contentbdbt">
                <h1>{{item.Title}}</h1>
                <img src="@/assets/images/downjt.png"
                @click="togglePanel(item, index)" 
                class="toggle-image"
                :class="{panelVisible: isPanelVisible.includes(index)}"
                >
            </div>
            <transition name="fade">
                <div class="ymlist" v-show="isPanelVisible.includes(index)">
                <ul>
                    <li 
                    v-for="(domain,domainIndex) in domainNamelist[item.Id] || []" 
                    :key="domain.Id" 
                    @click="dialogRight(domain,item.Id,domainIndex)"  
                    :class="{ active:selectedIndices[item.Id] === domainIndex}"
                    >
                     {{domain.DomainName}}
                    </li>
                </ul>
            </div>

            </transition>
        </div>
        <!-- 弹出组件 -->
            <Popup 
            :visible="visible" 
            :ruleForm="ruleForm"
            @update:visible="visible = $event"
            >
            </Popup>

</div>      

</template>
<script>
import Popup from '@/components/comomt/Popup.vue'
import axiosInstance  from '@/request';
export default {
    components:{
        Popup
    },
    props:{
        value:{
            type:Array,
        },
    },
    data() {
    return {
        domainNamelist: {},
        visible:false,
        isPanelVisible: [0],  // 控制面板的显示与隐藏
        selectedIndices: {}, // 用于存储当前选中的索引
        ruleForm:{
            domainName: '',
            YourName: '',
            email: '',
            content:''
        },


    };
  },
  methods: {
    // 切换面板显示状态
    togglePanel(item, index) {
        if(this.isPanelVisible.includes(index)) {
            this.isPanelVisible = this.isPanelVisible.filter((v) => v !== index)
        } else {
            this.isPanelVisible.push(index)
        }
        console.log(item.Id)
        this.getdomainNameList(item.Id)
        // item.isPanelVisible = !item.isPanelVisible
        // let list = JSON.parse(JSON.stringify(this.value))
        // list[index] = item
        // this.$emit('input', list)
    },
    dialogRight(domain, itemId, domainIndex) {
    this.visible = true;
    this.ruleForm.domainName = domain.DomainName;

    // 如果 selectedIndices[itemId] 未定义，初始化为 -1
    if (!this.selectedIndices[itemId] && this.selectedIndices[itemId] !== 0) {
        this.$set(this.selectedIndices, itemId, -1);
    }
    // 重置所有其他分类的选中状态
    for (let key in this.selectedIndices) {
      if (key !== itemId) {
        this.$set(this.selectedIndices, key, -1);
      }
    }
    // 更新选中索引
    this.$set(this.selectedIndices, itemId, domainIndex);
    console.log(this.selectedIndices);
    },
    getdomainNameList(seekId){
        axiosInstance.get('/api/overseas/domains/seek',{params:{seekId}})
        .then((res) =>{
            this.$set(this.domainNamelist, seekId, res.data || []);
        })
        console.log(seekId)
    }
  },
  mounted() {
    // 默认加载第一个列表的数据
    if (this.value.length > 0) {
      this.getdomainNameList(this.value[0].Id);
    }
  },
  watch: {
    // 监听 value 变化，确保异步加载时第一个列表展开
    value: {
      handler(newVal) {
        if (newVal.length > 0 && !this.domainNamelist[newVal[0].Id]) {
          this.getdomainNameList(newVal[0].Id);
        }
      },
      immediate: true, // 立即执行，确保初始值生效
    },
  },
};
</script>

<style>
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.contentbd{
    background-color: #fff;
    width: 1520px;
    margin: 0px 200px;
    height: auto;
    padding: 0 49px 50px 49px;
    margin-bottom: 32px;
}
.contentbdbt{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contentbdbt h1{
    font-size: 32px;
    font-weight: 500px;
    color:#131522;
    line-height: 48px;
    margin-left: 50px;
    margin-top: 32px;
    padding: 0;
    margin: 0;
}
.contentbdbt img{
    width: 32px;
    height: 32px;
    margin-top: 39px;
    margin-bottom: 25px;
}
.ymlist ul{
    display: flex;
    
    flex-wrap:wrap;
   
    border: 0.5px solid #EBEEF5;
    
}
.ymlist ul li{
    padding: 28px 61px 28px 32px;
    width: calc(100% / 6);
    border-right: 0.5px solid #EBEEF5;
    
    border-top: 0.5px solid #EBEEF5;
    background-color: #FAFBFC;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏溢出内容 */
    text-overflow: ellipsis; /* 显示省略号 */
}
.ymlist ul li:nth-child(6n){
    border-right: none;

}
.ymlist ul li:nth-child(-n+6){
    border-top: none;

}

.ymlist{
    font-size: 16px;
    color: #131522;
}
.toggle-image{
    cursor: pointer;
    transition: all 0.3s;

}
.panelVisible{
 transform: rotate(180deg);
 transform-origin: center center;
}
/* 添加过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.MessageFormstart h2{
    font-weight: 500;
    font-size: 32px;
    color: #131522;
}
.MessageFormstart p{
    font-weight: 400;
    font-size: 17px;
    color: #131522;
    line-height: 24px;
    padding-top: 32px;

}
.MessageForm{
    padding: 50px;
}
.MessageFormstart{
    padding-bottom: 50px;
}
.el-form-item__content{
    margin: 0!important;
}

.el-form-item__label{
    text-align: left!important;
    font-weight: 500!important;
    font-size: 16px!important;
    color: #131522!important;
    line-height: 24px!important;
    width: auto!important;
    padding-left: 16px!important;
}
.el-form-item__error{
    padding-left: 16px!important;
}
/* .FormLists > .el-form-item:first-child > .el-form-item__label {
    font-weight: 400!important;
    font-size: 12px!important;
    color: #8691A8!important;
} */
.FormListItem{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 32px;
}
.el-input__inner{
    border: none!important;
    border-bottom: 1px solid #EBEEF5!important;


}
.FormListItem::after{
    position: absolute;
    width: 16px;
    height: 2px;
    background: #FFAF80;
    bottom: 0px;
    left: 16px;
}
.FormListItemTare .el-textarea .el-textarea__inner{
    height: 219px;
    padding: 20px 24px 18px 24px;
    border: 1px solid #F3F6FC;
}
.FormListItemTare .el-textarea{
    margin-top: 24px;
}
.contactusText{
    width: 200px;
    height: 50px;
    background: #FFAF80;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #131522;
}
.contactus{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 32px;
}
.MessageFormYd{
    display: none;
}
.el-form-item:last-child{
    margin-bottom: 0;
}
.active{
    color:#FF7800
}
@media (max-width:768px) {
    .contentbd{
        width: 100%;
        margin:0;
        padding: 0;
    }
    .content{
        padding: 10px!important;
    }
    .ymlist ul li{
        width: calc(100% / 2);
    }
    .MessageFormYd{
        display: block;
        background-color: #F3F6FC;

    }
    .MessageForm{
        display: none;
    }
    .MessageFormYdHeader img{
        width: 17px;
        height: 17px;
        

    }
    .MessageFormYdHeader{
        background-color: #ffff;
    }
    .MessageFormYdHeader span{
        font-weight: 500;
        font-size: 17px;
        color: #1A1E34;
        line-height: 26px;
        margin-left: 113px;
    }
    .MessageFormYdHeader{
        display: flex;
        padding: 15px;
        justify-content:flex-start;
        align-items: center;
    }
    .MessageFormYdContent p{
        font-weight: 400;
        font-size: 14px;
        color: #8991B6;
        line-height: 24px;
    }
    .MessageFormYdContent{
        padding: 0px 15px;
    }
    .MessageFormYdFooter{
        background: #FFFFFF;
        border-radius: 8px;
        margin: 0px 10px;
        padding: 6px 15px 24px 15px;
    }
    .FormListItem::after{
        width: 0px;
    }
    .contactusText{
        width: 315px;
    }
    .contactus{
        padding-top: 20px;
    }
    .Morecontact p{
        font-weight: 400;
        font-size: 15px;
        color: #8991B6;
        line-height: 23px;
    }
    .Morecontact{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 28px 0px 59px 0px;
    }
    .MorecontactImg img {
    width: 24px!important;
    height: 24px!important;
    margin-right: 10px!important;

}
.MorecontactImg{
    margin-top: 19px;
}
.MorecontactImg img:last-child{
    margin-right: 0px!important;
}
}
</style>
  


