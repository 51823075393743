<script>
export default {
    methods:{
        goBack() {
            this.$router.go(-1); // 使用 router.go(-1) 返回上一页
        }
    }
}
</script>
<template>
    <div class="AboutUsheader">
        <img src="@/assets/images/jtleft.png" class="jtleft" @click="goBack">
        <div class="AboutUsheaderContent">
            <div class="AboutUsheaderText">
                <img src="@/assets/images/LE.png">
                <span>乐域</span>

            </div>
            <div class="AboutUsheaderls">
                <h3>DOMAIN INVESTMENT</h3>
                <div class="AboutUsheaderjs">
                    <p>LE .CN (深圳市微联科技服务有限公司） is an joint company founded by our shareholders in 2014. We are major 
                    <br>Premium Domain Investor and Broker in China. </p>
                    <p>We invest in premium domain names on behalf of our shareholders and domain name investors.</p>
                    <p>We serve premium domain owners with broker service and domain name liquidity service. </p>
                    <p>We provide mortgage loan service for premium domain name owners. </p>

                </div>

            </div>

        </div>

    </div>
</template>
<style>
.AboutUsheaderText{
    display: flex;
    align-items: flex-end;
    padding-left: 6px;
}
.AboutUsheaderText span{
    width: 111px;
    height: 50px;
    background: #FF9A5E;
    border-radius: 4px;
    font-weight: 500;
    font-size: 36px;
    color: #FFF2EA;
    line-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AboutUsheaderText img{
    width: 221px;
    height: 63px;
    margin-right: 32px;
}
.AboutUsheader{
    width: 100%;
    background-image: url('../../assets/images/headerbg.png');
    background-position: center center;  /* 背景图片居中 */
    background-repeat: no-repeat;  /* 防止背景图片重复 */
    height: 599px;
   
}
.AboutUsheaderContent{
    width: 930px;
    margin-left: 251px;
    padding-top: 89px;

    
}
.AboutUsheaderjs p{
    font-weight: 400;
    font-size: 17px;
    color: #131522;
    line-height: 24px;
}
.AboutUsheaderls h3{
    font-weight: 500;
    font-size: 56px;
    color: #131522;
    line-height: 84px;
    margin-top: 33px;
    margin-bottom: 27px;
}
.jtleft{
    width: 24px;
    height: 24px;
    margin-top: 41px;
    margin-left: 121px;
    cursor: pointer;
}
@media (max-width:768px) {
    .AboutUsheaderContent{
        width: 100%;
        margin: 0;
    }
    .jtleft{
        width: 20px;
        height: 20px;
        margin: 0px;   
    }
    .AboutUsheaderjs p{
        font-weight: 400;
        font-size: 15px;
        color: #131522;
        line-height: 24px;
    }
    .AboutUsheaderls h3{
        font-weight: 500;
        font-size: 28px;
        color: #131522;
        line-height: 42px;
    }
    .AboutUsheaderText span{
        width: 60px;
        height: 27px;
        font-weight: 500;
        font-size: 19px;
        color: #FFF2EA;
        line-height: 29px;
    }
    .AboutUsheaderText img{
        margin-right:13px;
    }
    .AboutUsheaderContent{
        padding-top: 24px;
    }
    .AboutUsheader{
        padding: 20px 15px 54px 15px;
        height: auto;
    }
    .AboutUsheaderText img{
        width: 127px;
        height: 43px;
    }
    .AboutUsheaderText{
        padding-left: 0px;
    }

    
}
</style>