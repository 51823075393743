// src/axios.js
import axios from "axios";

// 创建 Axios 实例
const axiosInstance = axios.create({
  baseURL: "", // 替换为你的 API 基础路径
  timeout: 5000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么，例如添加 Token
    const token = localStorage.getItem("token"); // 假设 Token 存储在 localStorage 中
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response.data; 
  },
  (error) => {
    // 对响应错误做点什么
    console.error("请求错误：", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;